import React from "react";
import { Link } from "gatsby";

import "../components/globalStyle.css";
import "../components/product.css";

import Header from "../components/header";
import BackButton from "../components/backButton";

const productCapitalOne = () => (
  <div className="productCapitalOneGroup">
    <Header />

    <BackButton />

    <div className="product">
      <div className="productText">
        <h2>Capital One</h2>
        <p className="productInfo">
          <i>
            Jun 2018 - Present
            <br />
            <br />
          </i>
        </p>

        <p>
          This work is legally a secret... Shoot me an
          <a href="mailto:thisiscelinepark@gmail.com"> email </a>
          to see it!
        </p>
        <br />
        <br />

        <iframe
          src="https://giphy.com/embed/NdKVEei95yvIY"
          width="480"
          height="270"
          frameBorder="0"
          class="giphy-embed"
          allowFullScreen
        />
        <p>
          <a href="https://giphy.com/gifs/mrw-smell-deskmate-NdKVEei95yvIY" />
        </p>
      </div>
    </div>
  </div>
);

export default productCapitalOne;
